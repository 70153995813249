import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { getGameById } from "../../../../api/game";
import { getParticipantsByGame } from "../../../../api/group";
import { alertError } from "../../../../utils/logger";
import Table, {
	imageFormatterGroup,
	imageFormatterIndividual,
	pointsFormatter,
	rankingPositionFormatter,
	timeFormatter,
} from "../../../components/tables/table";

function getData(participants) {
	let data = [];
	for (let i = 0; i < participants.length; ++i) {
		const elem = {};

		elem.rankingPosition = i + 1;
		elem.participant = participants[i]?.name;
		elem.image = participants[i]?.image;
		elem.score = participants[i]?.score;
		elem.time = participants[i]?.time;
		elem.id = participants[i]._id;

		data = data.concat(elem);
	}
	return data;
}

export default function RankingGamesPage() {
	const [data, setData] = useState([]);
	const [dataList, setDataList] = useState([]);
	const [game, setGame] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const gameId = useParams().id;

	const baseColumns = [
		{
			dataField: "image",
			text: "",
			headerAlign: "center",
			align: "center",
			formatter: game?.individual
				? imageFormatterIndividual
				: imageFormatterGroup,
		},
		{
			dataField: "participant",
			text: game?.individual ? "Participante" : "Grupo",
			headerAlign: "center",
			align: "center",
			sort: true,
		},
		{
			dataField: "score",
			text: "Score",
			headerAlign: "center",
			align: "center",
			formatter: pointsFormatter,
			sort: true,
		},
		{
			dataField: "time",
			text: "Time",
			headerAlign: "center",
			align: "center",
			formatter: timeFormatter,
			sort: true,
		},
	];

	const columns = !game?.individual
		? [
				{
					dataField: "rankingPosition",
					text: "",
					headerAlign: "center",
					align: "center",
					formatter: rankingPositionFormatter,
					sort: true,
				},
				...baseColumns,
		  ]
		: baseColumns;

	useEffect(() => {
		getGameById(gameId)
			.then((res) => {
				if (res.status === 200) {
					setGame(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get game",
				});
			});
		getParticipantsByGame(gameId)
			.then((res) => {
				if (res.status === 200) {
					setDataList(getData(res.data));
					setData(res.data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get ranking.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Ranking"></CardHeader>
				<CardBody>
					<Table data={dataList} columns={columns} />
				</CardBody>
			</Card>
		</>
	);
}
