import CheckIcon from "@material-ui/icons/Check";
import CheckBox from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import ClearIcon from "@material-ui/icons/Clear";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import TimerIcon from "@material-ui/icons/Timer";
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "../../../customStyles/bootstrap_table.css";
import { SERVER_URL } from "../../../utils";
import bronzeMedal from "../../assets/ranking/bronze-medal.png";
import goldMedal from "../../assets/ranking/gold-medal.png";
import silverMedal from "../../assets/ranking/silver-medal.png";
import teamPlaceholder from "../../assets/team-placeholder.png";
import individualPlaceholder from "../../assets/individual-placeholder.png";

const { SearchBar, ClearSearchButton } = Search;

export const buttonsStyle = {
	maxWidth: "30px",
	maxHeight: "30px",
	minWidth: "30px",
	minHeight: "30px",
	marginLeft: "1em",
};

export function imageFormatterGroup(imageURL) {
	return imageURL && imageURL !== "" ? (
		<Image
			src={`${SERVER_URL}/${imageURL}`}
			alt="image"
			roundedCircle
			width={45}
			height={45}
			style={{
				objectFit: "cover",
			}}
		/>
	) : (
		<Image
			src={teamPlaceholder}
			alt="image"
			roundedCircle
			width={45}
			height={45}
			style={{
				objectFit: "cover",
			}}
		/>
	);
}

export function imageFormatterIndividual(imageURL) {
	return imageURL && imageURL !== "" ? (
		<Image
			src={`${SERVER_URL}/${imageURL}`}
			alt="image"
			roundedCircle
			width={45}
			height={45}
			style={{
				objectFit: "cover",
			}}
		/>
	) : (
		<Image
			src={individualPlaceholder}
			alt="image"
			roundedCircle
			width={45}
			height={45}
			style={{
				objectFit: "cover",
			}}
		/>
	);
}

function extractContent(s) {
	if (!s) return "----";
	var span = document.createElement("span");
	span.innerHTML = s;
	return span.textContent || span.innerText;
}

export function substringFormatter(cell) {
	const text = extractContent(cell);
	return text.length > 50 ? text.substring(0, 50) + "..." : text;
}

export function booleanFormatter(cell) {
	return cell ? (
		<CheckBox style={{ color: "#D91E48" }} />
	) : (
		<CheckBoxOutlineBlank style={{ color: "#D91E48" }} />
	);
}

export function gameTypeFormatter(individual) {
	return (
		<div className="d-flex justify-content-center">
			<div className="duration-label">
				{individual ? "INDIVIDUAL" : "GRUPAL"}
			</div>
		</div>
	);
}

export function durationFormatter(longMode) {
	return (
		<div className="d-flex justify-content-center">
			<div className="duration-label">{longMode ? "LARGO" : "CORTO"}</div>
		</div>
	);
}

export function rankingPositionFormatter(rankingPosition) {
	return rankingPosition &&
		rankingPosition !== "" &&
		[1, 2, 3].includes(parseInt(rankingPosition)) ? (
		<div className="d-flex align-items-center justify-content-center">
			<div>{rankingPosition}</div>
			<Image
				className="ml-2"
				src={
					parseInt(rankingPosition) === 1
						? goldMedal
						: parseInt(rankingPosition) === 2
						? silverMedal
						: bronzeMedal
				}
				alt="medal"
				width={25}
			/>
		</div>
	) : (
		<div>{rankingPosition}</div>
	);
}

export function resultFormatter(result) {
	console.log("result", result);
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				padding: "2px",
				alignItems: "center",
			}}
		>
			{result && (
				<>
					<div
						style={{
							width: "95px",
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						{!result.hasOwnProperty("correct") ? (
							<>
								{result.pts}

								<EmojiEventsIcon
									style={{
										marginLeft: "5px",
										height: "15px",
										width: "15px",
									}}
								/>
							</>
						) : result.correct ? (
							<CheckIcon
								style={{
									marginLeft: "5px",
									height: "20px",
									width: "20px",
									color: "green",
								}}
							/>
						) : (
							<ClearIcon
								style={{
									marginLeft: "5px",
									height: "20px",
									width: "20px",
									color: "red",
								}}
							/>
						)}
					</div>
					<div
						style={{
							width: "95px",
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						{timeFormat(result.time)}

						<TimerIcon
							style={{
								marginLeft: "5px",
								height: "15px",
								width: "15px",
							}}
						/>
					</div>
				</>
			)}
		</div>
	);
}

export function pointsFormatter(cell) {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				padding: "2px",
				alignItems: "center",
			}}
		>
			<div
				style={{
					width: "95px",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{typeof cell != "boolean" ? (
					<>
						{cell}

						<EmojiEventsIcon
							style={{
								marginLeft: "5px",
								height: "15px",
								width: "15px",
							}}
						/>
					</>
				) : cell === true ? (
					<>
						<CheckIcon
							style={{
								marginInline: "5px",
								height: "20px",
								width: "20px",
								color: "green",
							}}
						/>
						Correct
					</>
				) : (
					<>
						<ClearIcon
							style={{
								marginInline: "5px",
								height: "20px",
								width: "20px",
								color: "red",
							}}
						/>
						Incorrect
					</>
				)}
			</div>
		</div>
	);
}

export function timeFormatter(cell) {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				padding: "2px",
				alignItems: "center",
			}}
		>
			{cell && (
				<div
					style={{
						width: "95px",
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{timeFormat(cell)}

					<TimerIcon
						style={{
							marginLeft: "5px",
							height: "15px",
							width: "15px",
						}}
					/>
				</div>
			)}
		</div>
	);
}

const buildDate = (date) => {
	if (Object.prototype.toString.call(date) !== "[object Date]") return;
	const displayDate = `${date.getDate()}/${date.getMonth() +
		1}/${date.getFullYear()}  ${date.getHours()}:${String(
		date.getMinutes()
	).padStart(2, "0")}`;
	return displayDate;
};

const timeFormat = (cell) => {
	let minutes = Math.floor(cell / 60);
	let seconds = cell - minutes * 60;
	if (minutes) return `${minutes} min ${seconds} s`;
	return `${seconds} s`;
};

export function dateFormatter(cell) {
	let date = new Date(cell);
	date = buildDate(date);

	return date;
}

const customTotal = (from, to, size) => (
	<span
		className="react-bootstrap-table-pagination-total"
		style={{ paddingLeft: "5px" }}
	>
		Mostrando {from}-{to} de {size} resultados
	</span>
);

/*function onColumnMatch({
  searchText,
  value,
  column,
  row
}) {
  if (searchText && value && typeof value === 'string') {
    return value.toLowerCase().startsWith(searchText)
  }
  return true;
}*/

const rowClasses = (row) => (row.active === false ? "inactive-row" : "");

export default function Table({
	data,
	columns,
	yearFilter,
	defaultSorted,
	sizePerPage,
	...tableProps
}) {
	const [year, setYear] = useState(null);

	const pagination = paginationFactory({
		page: 1,
		sizePerPage: sizePerPage || 10,
		lastPageText: ">>",
		firstPageText: "<<",
		nextPageText: ">",
		prePageText: "<",
		showTotal: true,
		paginationTotalRenderer: customTotal,
		alwaysShowAllBtns: false,
		onPageChange: function(page, sizePerPage) {},
		onSizePerPageChange: function(page, sizePerPage) {},
	});

	return (
		<ToolkitProvider
			bootstrap4
			keyField="id"
			data={
				yearFilter && year
					? data.filter((item) => item.year === year.toString())
					: data
			}
			columns={columns}
			search
		>
			{(props) => (
				<div>
					<SearchBar placeholder="Search" {...props.searchProps} />
					<ClearSearchButton
						className="ml-3"
						{...props.searchProps}
					/>
					{yearFilter && (
						<Datetime
							dateFormat="YYYY"
							timeFormat={false}
							className="col-3 px-5"
							onChange={(date) =>
								setYear(
									typeof date === "string"
										? null
										: date.year()
								)
							}
							inputProps={{
								placeholder: "Select a year...",
							}}
						/>
					)}
					<br />
					<br />
					<BootstrapTable
						wrapperClasses="table-responsive"
						classes="table table-head-custom table-vertical-center overflow-hidden"
						bordered={false}
						// remote
						pagination={pagination}
						striped
						rowClasses={rowClasses}
						defaultSorted={defaultSorted}
						{...tableProps}
						{...props.baseProps}
					/>
				</div>
			)}
		</ToolkitProvider>
	);
}

export function ResultsTable({
	data,
	columns,
	header,
	expandRow,
	...tableProps
}) {
	return (
		<ToolkitProvider
			bootstrap4
			keyField="scenario"
			data={data}
			columns={columns}
		>
			{(props) => (
				<div>
					<BootstrapTable
						wrapperClasses="table-responsive"
						classes="table table-head-custom table-vertical-center overflow-hidden"
						bordered={false}
						striped
						expandRow={expandRow}
						caption={header}
						{...tableProps}
						{...props.baseProps}
					/>
				</div>
			)}
		</ToolkitProvider>
	);
}

export function QuizResultsTable({ data, columns, ...tableProps }) {
	return (
		<ToolkitProvider bootstrap4 keyField="id" data={data} columns={columns}>
			{(props) => (
				<div>
					<br />
					<BootstrapTable
						wrapperClasses="table-responsive"
						classes="table-expanded table-expanded-head-solid table-expanded-striped table-expanded-head-custom table-expanded-vertical-center overflow-hidden"
						bordered={false}
						// remote
						{...tableProps}
						{...props.baseProps}
					/>
				</div>
			)}
		</ToolkitProvider>
	);
}
