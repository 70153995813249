import { Button, Tooltip } from "@material-ui/core";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { getScenes } from "../../../../api/scene";
import { alertError } from "../../../../utils/logger";
import Table, { dateFormatter } from "../../../components/tables/table";

function getData(scenes) {
	let data = [];
	for (let i = 0; i < scenes.length; ++i) {
		const elem = {};

		elem.fullName = scenes[i].fullName;
		elem.createdAt = scenes[i].createdAt;
		elem.id = scenes[i]._id;

		data = data.concat(elem);
	}
	return data;
}

export default function ScenesPage() {
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();

	function buttonFormatter(cell) {
		return (
			<>
				<Tooltip title="Ver estadísticas">
					<Button onClick={() => history.push("/scenarios/" + cell)}>
						<EqualizerIcon style={{ marginRight: "10px" }} />
						{"Respuestas"}
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: "fullName", text: "Título", sort: true },
		{
			dataField: "createdAt",
			text: "Creado el",
			align: "center",
			headerAlign: "center",
			formatter: dateFormatter,
			sort: true,
		},
		{
			dataField: "id",
			text: "Estadísticas",
			align: "center",
			headerAlign: "center",
			formatter: buttonFormatter,
		},
	];

	useEffect(() => {
		getScenes()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get scenarios.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Listado de escenarios"></CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
		</>
	);
}
