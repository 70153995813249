import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { getAnswersByScenario } from "../../../../api/answer";
import { getParticipants } from "../../../../api/participant";
import { getSceneById } from "../../../../api/scene";
import { alertError } from "../../../../utils/logger";
import Table, {
	pointsFormatter,
	timeFormatter,
} from "../../../components/tables/table";

function getData(participants, answers) {
	let data = [];
	participants.forEach((participant) => {
		let participantAnswers = answers.filter((answer) => {
			return answer.participant === participant._id;
		});
		let result = null;
		if (participantAnswers.length)
			result = participantAnswers?.reduce((a, b) => ({
				score: a.score + b.score,
				time: a.time + b.time,
			}));
		if (result) {
			data = data.concat({
				participant:
					participant?.name +
					(participant?.apellidos
						? " " + participant?.apellidos
						: ""),
				score: result.score,
				time: result.time,
			});
		}
	});

	return data;
}

export default function ViewScenePage() {
	const [data, setData] = useState([]);
	const [scenario, setScenario] = useState();
	const [refresh, setRefresh] = useState(false);
	const sceneId = useParams().id;

	const defaultSorted = [
		{
			dataField: "score",
			order: "desc",
		},
	];

	const columns = [
		{ dataField: "participant", text: "Participant" },
		{
			dataField: "score",
			text: "Score",
			align: "center",
			headerAlign: "center",
			formatter: pointsFormatter,
			sort: true,
		},
		{
			dataField: "time",
			text: "Time",
			align: "center",
			headerAlign: "center",
			formatter: timeFormatter,
			sort: true,
		},
	];

	useEffect(() => {
		getSceneById(sceneId)
			.then((res) => {
				if (res.status === 200) {
					setScenario(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get the scenario.",
				});
			});

		getAnswersByScenario(sceneId)
			.then((resAnswers) => {
				if (resAnswers.status === 200) {
					getParticipants()
						.then((res) => {
							if (res.status === 200) {
								setData(getData(res.data, resAnswers.data));
								setRefresh(false);
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage: "Could not get groups.",
							});
						});
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get groups.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title={`Scenario | ${scenario?.fullName || ""}`}>
					<CardHeaderToolbar></CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table
						data={data}
						columns={columns}
						defaultSorted={defaultSorted}
					/>
				</CardBody>
			</Card>
		</>
	);
}
