import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardHeader } from "../../_metronic/_partials/controls";
import { getGames, updateGame } from "../../api/game";
import { getParticipants } from "../../api/participant";
import { alertError, alertSuccess } from "../../utils/logger";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";

export function DashboardPage() {
	const [data, setData] = useState({
		numIndividualGames: 0,
		numGroupGames: 0,
		numIndividuals: 0,
		numGroups: 0,
	});
	const [game, setGame] = useState(null);
	const [active, setActive] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const history = useHistory();

	useEffect(() => {
		Promise.all([getParticipants(), getGames()])
			.then(([resParticipants, resGames]) => {
				let newData = { ...data };

				if (resParticipants.status === 200) {
					newData.numIndividuals = resParticipants?.data?.filter(
						(x) => x.individual
					)?.length;
					newData.numGroups = resParticipants?.data?.filter(
						(x) => !x.individual
					)?.length;
				}

				if (resGames.status === 200) {
					newData.numGroupGames = resGames?.data?.filter(
						(x) => !x.individual
					)?.length;
					newData.numIndividualGames = resGames?.data?.filter(
						(x) => x.individual
					)?.length;
				}

				setData(newData);
			})
			.catch((errors) => {
				errors.forEach((error) => {
					alertError({
						error: error,
						customMessage: "Could not get data.",
					});
				});
			});
	}, [refresh]);

	function handleGameStatus() {
		setActive((active) => !active);
		setGame({ ...game, active: !active });
		updateGame(game._id, { ...game, active: !active })
			.then((res) => {
				if (res.status === 200) {
					alertSuccess({
						title: "Success!",
						customMessage: !game.active
							? "Game Started"
							: "Game Ended",
					});
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not start the game.",
				});
			});
	}

	return (
		<>
			<Card>
				<CardHeader title={"Estadísticas"} />
				<CardBody>
					<div>
						<div className="row justify-content-center">
							<div
								className="col-12 col-md-4 text-center my-2 cursor-pointer"
								onClick={() =>
									history.push("/individual-games")
								}
							>
								<div class="card">
									<div class="card-body">
										<h5 class="card-title">
											Partidas individuales
										</h5>
										<div className="contentDash">
											{data.numIndividualGames}
										</div>
									</div>
								</div>
							</div>
							<div
								className="col-12 col-md-4 text-center my-2 cursor-pointer"
								onClick={() => history.push("/group-games")}
							>
								<div class="card">
									<div class="card-body">
										<h5 class="card-title">
											Partidas grupales
										</h5>
										<div className="contentDash">
											{data.numGroupGames}
										</div>
									</div>
								</div>
							</div>
							<div
								className="col-12 col-md-4 text-center my-2 cursor-pointer"
								onClick={() => history.push("/individuals")}
							>
								<div class="card">
									<div class="card-body">
										<h5 class="card-title">Individuales</h5>
										<div className="contentDash">
											{data.numIndividuals}
										</div>
									</div>
								</div>
							</div>
							<div
								className="col-12 col-md-4 text-center my-2 cursor-pointer"
								onClick={() => history.push("/groups")}
							>
								<div class="card">
									<div class="card-body">
										<h5 class="card-title">Grupos</h5>
										<div className="contentDash">
											{data.numGroups}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</CardBody>
				<ConfirmDialog
					title={`Are you sure you want to ${
						game?.active ? "end" : "start"
					} this game?`}
					open={openConfirmDialog}
					setOpen={setOpenConfirmDialog}
					onConfirm={handleGameStatus}
				/>
			</Card>
		</>
	);
}
