import { Button, Tooltip } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { getQuizzes } from "../../../../api/quiz";
import { alertError } from "../../../../utils/logger";
import Table, { dateFormatter } from "../../../components/tables/table";

function getData(quizzes) {
	let data = [];
	for (let i = 0; i < quizzes.length; ++i) {
		if (!quizzes[i].supertest) {
			const elem = {};
			elem.name = quizzes[i].fullName;
			elem.scenario = quizzes[i].scenario.fullName;
			elem.createdAt = quizzes[i].createdAt;
			elem.id = quizzes[i]._id;
			data = data.concat(elem);
		}
	}
	return data;
}

export default function QuizzesPage() {
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();

	function statisticsFormatter(cell) {
		return (
			<>
				<Tooltip title="Ver estadísticas">
					<Button onClick={() => history.push("/quizzes/" + cell)}>
						<EqualizerIcon style={{ marginRight: "10px" }} />
						{"Respuestas"}
					</Button>
				</Tooltip>
			</>
		);
	}

	function buttonFormatter(cell) {
		return (
			<>
				<Tooltip title="Editar">
					<Button onClick={() => history.push("/edit-quiz/" + cell)}>
						<Edit />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: "name", text: "Título", sort: true },
		{ dataField: "scenario", text: "Escenario", sort: true },
		{
			dataField: "createdAt",
			text: "Creado el",
			align: "center",
			headerAlign: "center",
			formatter: dateFormatter,
			sort: true,
		},
		{
			dataField: "id",
			text: "Estadísticas",
			align: "center",
			headerAlign: "center",
			formatter: statisticsFormatter,
		},
		{
			dataField: "id",
			text: "",
			align: "center",
			headerAlign: "center",
			formatter: buttonFormatter,
		},
	];

	useEffect(() => {
		getQuizzes()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get quizzes.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Listado de pruebas"></CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
		</>
	);
}
