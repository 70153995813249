import { Button, Tooltip } from "@material-ui/core";
import { QuestionAnswer } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import {
	deleteParticipant,
	getParticipants,
} from "../../../../api/participant";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import TableDialog from "../../../components/dialogs/TableDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
	durationFormatter,
	imageFormatterIndividual,
} from "../../../components/tables/table";

function getData(participants) {
	let data = [];
	for (let i = 0; i < participants?.length; ++i) {
		const elem = {};

		elem.name = participants[i].name + " " + participants[i].apellidos;
		elem.image = participants[i].image || "";
		elem.email = participants[i].email;
		elem.hospital = participants[i].hospital;
		elem.longMode = participants[i]?.game?.longMode;
		elem.createdAt = participants[i].createdAt;

		elem.id = participants[i]._id;

		data = data.concat(elem);
	}
	return data;
}

function getSurveyAnswerData(surveyAnswers) {
	let data = [];
	for (let i = 0; i < surveyAnswers?.length; ++i) {
		const elem = {};

		elem.title = surveyAnswers[i]?.survey?.title;
		elem.score = surveyAnswers[i]?.score;
		elem.id = surveyAnswers[i]._id;

		data = data.concat(elem);
	}
	return data;
}

export default function IndividualsPage() {
	const [data, setData] = useState([]);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [selectedInvididual, setSelectedIndividual] = useState(null);
	const [openSurveyAnswersDialog, setOpenSurveyAnswersDialog] = useState(
		null
	);

	const [refresh, setRefresh] = useState(false);
	const history = useHistory();

	function buttonFormatter(cell) {
		const elem = data?.find((x) => x.id === cell);
		return (
			<>
				<Tooltip title="Ver respuestas encuesta">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setSelectedIndividual(elem);
							setOpenSurveyAnswersDialog(true);
						}}
					>
						<QuestionAnswer />
					</Button>
				</Tooltip>
				<Tooltip title="Editar">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => history.push("/edit-individual/" + cell)}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{/*<Tooltip title="Eliminar">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setSelectedIndividual(elem);
							setOpenConfirmDialog(true);
						}}
					>
						<DeleteIcon />
					</Button>
					</Tooltip>*/}
			</>
		);
	}

	const columns = [
		{
			dataField: "image",
			text: "",
			headerAlign: "center",
			align: "center",
			formatter: imageFormatterIndividual,
		},
		{ dataField: "name", text: "Nombre completo", sort: true },
		{
			dataField: "email",
			text: "E-mail",
			headerAlign: "center",
			align: "center",
			sort: true,
		},
		{
			dataField: "hospital",
			text: "Hospital",
			headerAlign: "center",
			align: "center",
			sort: true,
		},
		{
			dataField: "longMode",
			text: "Modo",
			sort: true,
			headerAlign: "center",
			align: "center",
			formatter: durationFormatter,
		},
		{
			dataField: "createdAt",
			text: "Creado el",
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	useEffect(() => {
		getParticipants()
			.then((res) => {
				if (res.status === 200) {
					setData(
						getData(res.data.filter((x) => x?.game?.individual))
					);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get participants.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Listado de usuarios individuales"></CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={
							"Are you sure you want to remove this participant?"
						}
						open={openConfirmDialog}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteParticipant(selectedInvididual?.id)
								.then((res) => {
									if (res.status === 204) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Participant removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Could not remove participant.",
									});
								});
						}}
					/>
					<PreviewDialog
						title={selectedInvididual?.name}
						open={openPreviewDialog}
						setOpen={setOpenPreviewDialog}
						src={`${SERVER_URL}/${selectedInvididual?.image}`}
					></PreviewDialog>
				</CardBody>
				<TableDialog
					title={`Respuestas encuesta de: ${
						selectedInvididual?.name
					} ${
						selectedInvididual?.apellidos
							? selectedInvididual?.apellidos
							: ""
					}`}
					open={openSurveyAnswersDialog}
					setOpen={setOpenSurveyAnswersDialog}
					data={getSurveyAnswerData(
						selectedInvididual?.surveyAnswers
					)}
				></TableDialog>
			</Card>
		</>
	);
}
