import { Button, Snackbar, Tooltip } from "@material-ui/core";
import { Assessment } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { deleteGame, getGames, setDefaultGame } from "../../../../api/game";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
	durationFormatter,
	pointsFormatter,
} from "../../../components/tables/table";

function getData(games) {
	let data = [];
	console.log("games", games);
	for (let i = 0; i < games.length; ++i) {
		if (games[i].individual) {
			const elem = {};

			elem.globalRankingPosition =
				games[i].globalRankingPosition !== -1
					? games[i].globalRankingPosition + 1
					: "---";
			elem.score = games[i]?.score || 0;
			elem.longMode = games[i].longMode;
			elem.createdAt = games[i].createdAt;
			elem.data = games[i];

			data = data.concat(elem);
		}
	}
	return data;
}

export default function IndividualGamesPage() {
	const [data, setData] = useState([]);
	const [gameId, setGameId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();

	function buttonFormatter(game) {
		return (
			<>
				<Tooltip title="Ranking">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() =>
							history.push(
								"/individual-games/" + game?._id + "/ranking"
							)
						}
					>
						<EmojiEventsIcon />
					</Button>
				</Tooltip>
				<Tooltip title="Resultados">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() =>
							history.push(
								"/individual-games/" + game?._id + "/results"
							)
						}
					>
						<Assessment />
					</Button>
				</Tooltip>
				<Tooltip title="Eliminar">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setGameId(game?._id);
							setOpenConfirmDialog(2);
						}}
					>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{
			dataField: "globalRankingPosition",
			text: "Posición ranking individual",
			headerAlign: "center",
			align: "center",
			sort: true,
		},
		{
			dataField: "score",
			text: "Puntuación",
			headerAlign: "center",
			align: "center",
			formatter: pointsFormatter,
			sort: true,
		},
		{
			dataField: "longMode",
			text: "Modo",
			headerAlign: "center",
			align: "center",
			formatter: durationFormatter,
			sort: true,
		},
		{
			dataField: "createdAt",
			text: "Creado el",
			headerAlign: "center",
			align: "center",
			formatter: dateFormatter,
			sort: true,
		},
		{
			dataField: "data",
			text: "",
			formatter: buttonFormatter,
			align: "end",
		},
	];

	useEffect(() => {
		getGames()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get games.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Listado de partidas"></CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={
							"¿Estás seguro de que quieres eliminar esta partida?"
						}
						children={
							"Se eliminarán también sus participantes y sus respuestas"
						}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteGame(gameId)
								.then((res) => {
									if (res.status === 204) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Game removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: "Could not remove game.",
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
