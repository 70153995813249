import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import { getParticipants } from "../../api/participant";
import { alertError } from "../../utils";
import Table, {
	imageFormatterGroup,
	imageFormatterIndividual,
	pointsFormatter,
	rankingPositionFormatter,
	timeFormatter,
} from "../components/tables/table";

function getIndividualRankingData(participants, shortMode, longMode) {
	let individuals = participants.filter((x) => x?.game?.individual);

	if (shortMode && !longMode)
		individuals = individuals.filter((x) => !x?.game?.longMode);
	if (!shortMode && longMode)
		individuals = individuals.filter((x) => x?.game?.longMode);

	let individualParticipants = individuals.sort((a, b) => {
		if (a.score !== b.score) {
			return b.score - a.score;
		}
		return a.time - b.time;
	});

	let data = [];

	for (let i = 0; i < individualParticipants.length; ++i) {
		const elem = {};

		elem.rankingPosition = i + 1;
		elem.participant = individualParticipants[i]?.name;
		elem.image = individualParticipants[i]?.image;
		elem.score = individualParticipants[i]?.score;
		elem.time = individualParticipants[i]?.time || "0";
		elem.id = individualParticipants[i]._id;

		data = data.concat(elem);
	}

	return data;
}

function getGroupRankingData(participants, shortMode, longMode) {
	let groups = participants.filter((x) => !x?.game?.individual);

	if (shortMode && !longMode)
		groups = groups.filter((x) => !x?.game?.longMode);
	if (!shortMode && longMode)
		groups = groups.filter((x) => x?.game?.longMode);

	let groupParticipants = groups.sort((a, b) => {
		if (a.score !== b.score) {
			return b.score - a.score;
		}
		return a.time - b.time;
	});

	let data = [];

	for (let i = 0; i < groupParticipants.length; ++i) {
		const elem = {};

		elem.rankingPosition = i + 1;
		elem.participant = groupParticipants[i]?.name;
		elem.image = groupParticipants[i]?.image;
		elem.score = groupParticipants[i]?.score;
		elem.time = groupParticipants[i]?.time || "0";
		elem.id = groupParticipants[i]._id;

		data = data.concat(elem);
	}

	return data;
}

export default function RankingPage() {
	const [data, setData] = useState([]);
	const [displayData, setDisplayData] = useState([]);
	const [individualView, setIndividualView] = useState(false);
	const [modeFilter, setModeFilter] = useState({
		shortMode: true,
		longMode: true,
	});

	const [refresh, setRefresh] = useState(false);

	const columns = [
		{
			dataField: "rankingPosition",
			text: "",
			headerAlign: "center",
			align: "center",
			formatter: rankingPositionFormatter,
			sort: true,
		},
		{
			dataField: "image",
			text: "",
			headerAlign: "center",
			align: "center",
			formatter: individualView
				? imageFormatterIndividual
				: imageFormatterGroup,
		},
		{
			dataField: "participant",
			text: individualView ? "Participante" : "Grupo",
			headerAlign: "center",
			align: "center",
			sort: true,
		},
		{
			dataField: "score",
			text: "Puntuación",
			headerAlign: "center",
			align: "center",
			formatter: pointsFormatter,
			sort: true,
		},
		{
			dataField: "time",
			text: "Tiempo",
			headerAlign: "center",
			align: "center",
			formatter: timeFormatter,
			sort: true,
		},
	];

	useEffect(() => {
		getParticipants()
			.then((res) => {
				if (res.status === 200) {
					let participants = res.data;
					setData(participants);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not obtain ranking",
				});
			});
	}, [refresh]);

	useEffect(() => {
		if (!data.length) return;
		setDisplayData(
			individualView
				? getIndividualRankingData(
						data,
						modeFilter.shortMode,
						modeFilter.longMode
				  )
				: getGroupRankingData(
						data,
						modeFilter.shortMode,
						modeFilter.longMode
				  )
		);
	}, [individualView, data, modeFilter]);

	return (
		<>
			<Card>
				<CardHeader
					title={`Ranking ${
						individualView ? "Individual" : "Grupal"
					}`}
				>
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							style={{
								backgroundColor: individualView
									? "#84bd00"
									: "",
								borderColor: individualView ? "#84bd00" : "",
							}}
							onClick={() => setIndividualView(true)}
						>
							Individual
						</button>
						<button
							type="button"
							className="btn btn-primary ml-3"
							style={{
								backgroundColor: !individualView
									? "#84bd00"
									: "",
								borderColor: !individualView ? "#84bd00" : "",
							}}
							onClick={() => setIndividualView(false)}
						>
							Grupal
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<div className="d-flex align-items-center">
						<div>
							<FormControlLabel
								control={
									<Checkbox
										style={{ color: "#d81925" }}
										checked={modeFilter.shortMode}
										onChange={() => {
											setModeFilter({
												...modeFilter,
												shortMode: !modeFilter.shortMode,
												longMode:
													modeFilter.longMode ||
													modeFilter.shortMode,
											});
										}}
										name="checkActive"
									/>
								}
								label="Modo corto"
							/>
						</div>
						<div className="ml-4">
							<FormControlLabel
								control={
									<Checkbox
										style={{ color: "#d81925" }}
										checked={modeFilter.longMode}
										onChange={() => {
											setModeFilter({
												...modeFilter,
												longMode: !modeFilter.longMode,
												shortMode:
													modeFilter.shortMode ||
													modeFilter.longMode,
											});
										}}
										name="checkActive"
									/>
								}
								label="Modo largo"
							/>
						</div>
					</div>
					<br />
					<Table
						data={displayData}
						columns={columns}
						sizePerPage={50}
					/>
				</CardBody>
			</Card>
		</>
	);
}
