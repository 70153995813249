import {
	Button,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
	deleteParticipant,
	getParticipantById,
	postParticipant,
	updateParticipant,
} from "../../../../api/participant";
import { SERVER_URL } from "../../../../utils";
import { alertError, alertSuccess } from "../../../../utils/logger";
import AddMemberDialog from "../../../components/dialogs/AddMemberDialog";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import Table, { buttonsStyle } from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyParticipant(isGroup) {
	if (isGroup)
		return {
			name: "",
			hospital: "",
			members: [],
			isGroup: isGroup,
			image: "",
			gameSessionCode: null,
			game: "64afe1306172ed24f867e65e",
			longGameDuration: false,
		};

	return {
		name: "",
		apellidos: "",
		email: "",
		hospital: "",
		image: "",
		isGroup: isGroup,
		game: "64afe1306172ed24f867e65e",
		longGameDuration: true,
	};
}

function getMemberData(members) {
	let data = [];
	for (let i = 0; i < members.length; ++i) {
		const elem = {};

		elem.fullName = members[i];
		elem.id = i;

		data = data.concat(elem);
	}
	return data;
}

export default function EditParticipantsPage() {
	const location = useLocation();
	const isGroup = location.pathname.toString().includes("edit-group");

	const [participant, setParticipant] = useState(
		getEmptyParticipant(isGroup)
	);
	const [selectedImage, setSelectedImage] = useState(null);
	const [openPreviewImage, setOpenPreviewImage] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [openAddMemberDialog, setOpenAddMemberDialog] = useState(false);

	const participantId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!participantId) {
			disableLoadingData();
			return;
		}
		getParticipantById(participantId)
			.then((res) => {
				if (res.status === 200) {
					setParticipant(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get participant.",
				});
				handleBack();
			});
	}, [participantId, disableLoadingData, history]);

	function saveParticipant() {
		if (!participantId) {
			postParticipant(participant, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Participant successfully created.",
						});
						handleBack();
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save participant.",
					});
				});
		} else {
			updateParticipant(participantId, participant, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						handleBack();
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleBack = () => {
		if (isGroup) history.push("/groups");
		else history.push("/individuals");
	};

	const handleChange = (element) => (event) => {
		setParticipant({ ...participant, [element]: event.target.value });
	};

	function buttonFormatter(index) {
		const newMembers = [...participant?.members];
		newMembers.splice(index, 1);
		return (
			<>
				<div className="d-flex justify-content-end">
					<Tooltip title="Eliminar">
						<Button
							style={buttonsStyle}
							size="small"
							onClick={() => {
								setParticipant({
									...participant,
									members: newMembers,
								});
							}}
						>
							<Delete />
						</Button>
					</Tooltip>
				</div>
			</>
		);
	}

	const memberColumns = [
		{ dataField: "fullName", text: "Nombre completo", sort: true },
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader
						title={isGroup ? "Editar grupo" : "Editar individual"}
					></CardHeader>
					<CardBody>
						<TextField
							id={`name`}
							label="Nombre"
							autoComplete="off"
							value={participant.name}
							onChange={handleChange("name")}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							variant="outlined"
							required
						/>
						{!isGroup && (
							<>
								<TextField
									id={`apellidos`}
									label="Apellidos"
									autoComplete="off"
									value={participant.apellidos}
									onChange={handleChange("apellidos")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
									required
								/>
								<TextField
									id={`email`}
									label="E-mail"
									autoComplete="off"
									value={participant.email}
									onChange={handleChange("email")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
									required
								/>
								<TextField
									id={`hospital`}
									label="Hospital"
									autoComplete="off"
									value={participant.hospital}
									onChange={handleChange("hospital")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
									required
								/>
							</>
						)}
						<br />
						<label className="mt-4" htmlFor={"upload-image"}>
							<input
								style={{ display: "none" }}
								id={"upload-image"}
								name={"upload-image"}
								type="file"
								accept="image/*"
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="outlined"
							>
								{selectedImage || participant.image !== ""
									? "Change image"
									: "Upload image"}
							</Button>
						</label>
						{(selectedImage || participant.image !== "") && (
							<>
								<Button
									onClick={() => setOpenPreviewImage(true)}
									style={{ marginRight: "15px" }}
									variant="outlined"
								>
									{"Preview image"}
								</Button>
								<PreviewDialog
									title={"Preview image"}
									open={openPreviewImage}
									setOpen={setOpenPreviewImage}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${participant.image}`
									}
								/>
							</>
						)}
					</CardBody>
				</Card>
				{isGroup && (
					<>
						<br />
						<br />
						<br />
						<Card>
							<CardHeader title="Miembros">
								<CardHeaderToolbar>
									<button
										type="button"
										className="btn btn-primary"
										onClick={() =>
											setOpenAddMemberDialog(true)
										}
									>
										Añadir
									</button>
								</CardHeaderToolbar>
							</CardHeader>
							<CardBody>
								{participant.members.length > 0 && (
									<Table
										data={getMemberData(
											participant.members
										)}
										columns={memberColumns}
									/>
								)}
							</CardBody>
						</Card>
					</>
				)}
				<Button
					onClick={handleBack}
					variant="outlined"
					style={{ marginRight: "20px" }}
				>
					Atrás
				</Button>
				<Button
					onClick={() => saveParticipant()}
					variant="outlined"
					color="primary"
					style={{ marginRight: "20px" }}
				>
					{isGroup
						? "Guardar grupo"
						: "Guardar participante individual"}
				</Button>
				<AddMemberDialog
					title="Añadir miembro"
					open={openAddMemberDialog}
					setOpen={setOpenAddMemberDialog}
					onConfirm={(groupMember) => {
						let groupMembers = [...participant.members];
						let newMember = `${groupMember?.name} ${groupMember?.apellidos}`;
						newMember.trim();
						groupMembers.push(newMember);
						setParticipant({
							...participant,
							members: groupMembers,
						});
					}}
				></AddMemberDialog>
				{/*{participantId && (
					<>
						<MuiThemeProvider theme={theme}>
							<Button
								onClick={() => setOpenConfirmDialog(true)}
								variant="outlined"
								color="secondary"
							>
								{isGroup
									? "Eliminar grupo"
									: "Eliminar participante"}
							</Button>
						</MuiThemeProvider>

						<ConfirmDialog
							title={
								"¿Estás seguro de que quieres eliminar este grupo?"
							}
							open={openConfirmDialog}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								deleteParticipant(participantId)
									.then((res) => {
										if (res.status === 204) {
											alertSuccess({
												title: "Deleted!",
												customMessage:
													"Participant deleted successfully",
											});
											handleBack();
										}
									})
									.catch((error) => {
										alertError({
											error: error,
											customMessage:
												"Could not delete participant.",
										});
									});
							}}
						/>
					</>
						)}*/}
			</>
		);
}
