import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { getAnswers } from "../../../../api/answer";
import { getParticipantsByGame } from "../../../../api/group";
import { getQuizzes } from "../../../../api/quiz";
import { getScenes } from "../../../../api/scene";
import { alertError } from "../../../../utils";
import {
	QuizResultsTable,
	ResultsTable,
	resultFormatter,
} from "../../../components/tables/table";
import { getGameById } from "../../../../api/game";

function getGameQuizzesData(participants, scenarios, quizzes, answers) {
	let data = [];

	scenarios.forEach((scenario) => {
		let quizzesRes = [];
		quizzes
			.filter(
				(quiz) => quiz.scenario._id === scenario._id && !quiz.supertest
			)
			.forEach((quiz) => {
				let quizRes = {};
				participants.forEach((participant, indexGroup) => {
					let answer = answers.find(
						(answer) =>
							answer.quiz === quiz._id &&
							answer.participant === participant._id
					);
					if (answer) {
						quizRes[`participant${indexGroup + 1}`] = {
							pts: answer.score,
							time: answer.time,
							name: participant.fullName,
						};
					}
				});
				quizRes.quiz = quiz.fullName;
				quizzesRes.push(quizRes);
			});

		let elem = { scenario: scenario.fullName, results: quizzesRes };
		data.push(elem);
	});
	return data;
}

function getGameData(participants, scenarios, answers) {
	let data = [];

	scenarios.forEach((scenario, indexScenario) => {
		let elem = {};
		elem.scenario = `Scenario ${indexScenario + 1}`;
		elem.scenarioName = scenario.fullName;
		participants.forEach((participant, indexGroup) => {
			let participantAnswers = answers.filter((answer) => {
				return (
					answer.scenario === scenario._id &&
					answer.participant === participant._id
				);
			});
			let result = null;
			if (participantAnswers.length)
				result = participantAnswers?.reduce((a, b) => ({
					score: a.score + b.score,
					time: a.time + b.time,
				}));
			if (result)
				elem[`participant${indexGroup + 1}`] = {
					pts: result.score,
					time: result.time,
					name: participant.name,
				};
		});
		data = data.concat(elem);
	});

	return data;
}

export function ResultsGamePage() {
	const [participants, setParticipants] = useState([]);
	const [quizzes, setQuizzes] = useState([]);
	const [answers, setAnswers] = useState([]);
	const [scenarios, setScenarios] = useState([]);
	const [quizzesData, setQuizzesData] = useState([]);
	const [actualGame, setActualGame] = useState(null);
	const [data, setData] = useState([]);
	const [expandedRows, setExpandedRows] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);
	const history = useHistory();
	const gameId = useParams().id;

	function styleFormatter(cell, row) {
		if (cell) {
			let max = true;
			let min = true;
			for (let i = 1; i < 10; ++i) {
				if (
					row[`participant${i}`]?.pts > cell.pts ||
					(row[`participant${i}`]?.pts === cell.pts &&
						row[`participant${i}`]?.time < cell.time)
				) {
					max = false;
				}

				if (
					row[`participant${i}`]?.pts < cell.pts ||
					(row[`participant${i}`]?.pts === cell.pts &&
						row[`participant${i}`]?.time > cell.time)
				) {
					min = false;
				}
				if (!min && !max) {
					return;
				}
			}
			if (max) return { backgroundColor: "#c8e6c9" };
			else if (min) return { backgroundColor: "#e6c8c8" };
		}
	}

	const header = (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
			}}
		>
			<h4
				style={{
					textAlign: "center",
					color: "rgb(24, 28, 50)",
					border: "1px solid rgb(215, 218, 231)",
					borderWidth: "0px 0px 2px 0px",
					padding: "0.5em",
				}}
			>
				{"Results"}
			</h4>
		</div>
	);

	function groupFormatter(column, colIndex) {
		return <div>{participants[colIndex - 1]?.fullName || column.text}</div>;
	}

	const handleOnExpandRow = (row, isExpand, rowIndex, e) => {
		if (isExpand) {
			setExpandedRows([...expandedRows, row.scenario]);
		} else {
			setExpandedRows(expandedRows.filter((x) => x !== row.scenario));
		}
	};

	const expandRow = {
		renderer: (row) => (
			<div style={{ margin: "10px" }}>
				<b>{`${row.scenarioName} | Quizzes Results`}</b>
				<QuizResultsTable
					data={
						quizzesData[row.scenario.match(/\d+/)[0] - 1]?.results
					}
					columns={data ? generateQuizColumns() : []}
				/>
			</div>
		),
		showExpandColumn: true,
		onlyOneExpanding: true,
		expanded: expandedRows,
		onExpand: handleOnExpandRow,
		expandHeaderColumnRenderer: ({ isAnyExpands }) => {
			if (isAnyExpands) {
				return <b>-</b>;
			}
			return <b>+</b>;
		},
		expandColumnRenderer: ({ expanded }) => {
			if (expanded) {
				return <b>-</b>;
			}
			return <b>...</b>;
		},
	};

	useEffect(() => {
		if (!gameId) {
			history.push("/games");
			return;
		}

		getGameById(gameId)
			.then((res) => {
				if (res.status === 200) {
					setActualGame(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get game",
				});
				history.push("/games");
			});

		Promise.all([
			getQuizzes(),
			getScenes(),
			getAnswers(),
			getParticipantsByGame(gameId),
		])
			.then(([resQuizzes, resScenes, resAnswers, resParticipants]) => {
				if (resQuizzes.status === 200) {
					setQuizzes(resQuizzes.data);
				}

				if (resScenes.status === 200) {
					setScenarios(resScenes.data);
				}

				if (resAnswers.status === 200) {
					setAnswers(resAnswers.data);
				}

				if (resParticipants.status === 200) {
					setParticipants(resParticipants.data);
					setQuizzesData(
						getGameQuizzesData(
							resParticipants.data,
							resScenes.data,
							resQuizzes.data,
							resAnswers.data
						)
					);
					setData(
						getGameData(
							resParticipants.data,
							resScenes.data,
							resAnswers.data
						)
					);
					setRefresh(false);
				}
			})
			.catch((errors) => {
				alertError({
					error: null,
					customMessage: "Could not get data.",
				});
			});
	}, [gameId, refresh]);

	function generateQuizColumns() {
		let columns = [{ dataField: "quiz", text: "Quiz" }];

		participants.forEach((participant, index) => {
			columns.push({
				dataField: `participant${index + 1}`,
				text: `${participant?.name}${
					participant?.apellidos ? " " + participant?.apellidos : ""
				}`,
				headerAlign: "center",
				align: "center",
				headerFormatter: groupFormatter,
				formatter: resultFormatter,
			});
		});

		return columns;
	}

	function generateGameColumns() {
		let columns = [{ dataField: "scenarioName", text: "Scenario" }];

		participants.forEach((participant, index) => {
			columns.push({
				dataField: `participant${index + 1}`,
				text: `${participant?.name}${
					participant?.apellidos ? " " + participant?.apellidos : ""
				}`,
				headerAlign: "center",
				headerFormatter: groupFormatter,
				formatter: resultFormatter,
				style: styleFormatter,
			});
		});

		return columns;
	}

	return (
		<>
			<Card>
				<CardHeader title={"Game results"}></CardHeader>
				<CardBody>
					<ResultsTable
						data={data}
						columns={data ? generateGameColumns() : []}
						header={header}
						expandRow={expandRow}
					/>
				</CardBody>
			</Card>
		</>
	);
}
