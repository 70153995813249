import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@material-ui/core";
import React, { useState } from "react";

function getEmptyMember() {
	return {
		name: "",
		apellidos: "",
	};
}

const AddMemberDialog = (props) => {
	const { title, open, setOpen, onConfirm } = props;
	const [member, setMember] = useState(getEmptyMember());
	const [errorMessage, setErrorMessage] = useState(null);

	return (
		<Dialog
			open={open}
			onClose={() => {
				setOpen(false);
				setMember(getEmptyMember());
				setErrorMessage(null);
			}}
			aria-labelledby="add-member-dialog"
		>
			<DialogTitle id="add-member-dialog">{title}</DialogTitle>
			<DialogContent>
				<TextField
					id={`name`}
					label="Nombre"
					autoComplete="off"
					value={member?.name || ""}
					onChange={(e) =>
						setMember({ ...member, name: e.target.value })
					}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
				<TextField
					id={`apellidos`}
					label="Apellidos"
					autoComplete="off"
					value={member?.apellidos || ""}
					onChange={(e) =>
						setMember({ ...member, apellidos: e.target.value })
					}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
				<br />
				<p style={{ color: "red" }}>{errorMessage}</p>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					onClick={() => {
						setErrorMessage(null);
						setMember(getEmptyMember());
						setOpen(false);
					}}
				>
					Cancel
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (!member?.name || !member?.apellidos) {
							setErrorMessage(
								"¡Nombre y apellidos son necesarios!"
							);

							return;
						}
						setErrorMessage(null);
						setMember(getEmptyMember());
						setOpen(false);
						onConfirm(member);
					}}
				>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default AddMemberDialog;
