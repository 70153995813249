import { API, /* baseClient, */ authClient } from "../../utils/index";

export const getSurveyAnswersByParticipant = (participantId) => {
	return authClient().get(
		`${API}/survey-answer/by-participant/${participantId}`
	);
};

export const getSurveyAnswersBySurvey = (surveyId) => {
	return authClient().get(`${API}/survey-answer/by-survey/${surveyId}`);
};
