import { Button, Tooltip } from "@material-ui/core";
import { QuestionAnswer } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import {
	deleteParticipant,
	getParticipants,
} from "../../../../api/participant";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import TableDialog from "../../../components/dialogs/TableDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
	durationFormatter,
	imageFormatterGroup,
} from "../../../components/tables/table";

function getData(groups) {
	console.log("groups", groups);
	let data = [];
	for (let i = 0; i < groups.length; ++i) {
		const elem = {};

		elem.name = groups[i].name;
		elem.hospital = groups[i]?.game?.hospital || "---";
		elem.sessionCode = groups[i]?.game?.sessionCode || "---";
		elem.image = groups[i].image || "";
		elem.longMode = groups[i].game?.longMode;
		elem.surveyAnswers = groups[i]?.surveyAnswers;
		elem.createdAt = groups[i].createdAt;
		elem.id = groups[i]._id;

		data = data.concat(elem);
	}
	return data;
}

function getSurveyAnswerData(surveyAnswers) {
	let data = [];
	for (let i = 0; i < surveyAnswers?.length; ++i) {
		const elem = {};

		elem.title = surveyAnswers[i]?.survey?.title;
		elem.score = surveyAnswers[i]?.score;
		elem.id = surveyAnswers[i]._id;

		data = data.concat(elem);
	}
	return data;
}

export default function GroupsPage() {
	const [data, setData] = useState([]);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState(null);
	const [openSurveyAnswersDialog, setOpenSurveyAnswersDialog] = useState(
		null
	);

	const [refresh, setRefresh] = useState(false);
	const history = useHistory();

	function buttonFormatter(cell) {
		const elem = data?.find((x) => x.id === cell);
		return (
			<>
				<Tooltip title="Ver respuestas encuesta">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setSelectedGroup(elem);
							setOpenSurveyAnswersDialog(true);
						}}
					>
						<QuestionAnswer />
					</Button>
				</Tooltip>
				<Tooltip title="Editar">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => history.push("/edit-group/" + cell)}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{/*<Tooltip title="Eliminar">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setSelectedGroup(elem);
							setOpenConfirmDialog(true);
						}}
					>
						<DeleteIcon />
					</Button>
					</Tooltip>*/}
			</>
		);
	}

	const columns = [
		{
			dataField: "image",
			text: "",
			headerAlign: "center",
			align: "center",
			formatter: imageFormatterGroup,
		},
		{ dataField: "name", text: "Nombre", sort: true },
		{
			dataField: "hospital",
			text: "Hospital",
			headerAlign: "center",
			align: "center",
			sort: true,
		},
		{
			dataField: "sessionCode",
			text: "Código sesión",
			headerAlign: "center",
			align: "center",
			sort: true,
		},
		{
			dataField: "longMode",
			text: "Modo partida",
			sort: true,
			headerAlign: "center",
			align: "center",
			formatter: durationFormatter,
		},
		{
			dataField: "createdAt",
			text: "Creado el",
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	useEffect(() => {
		getParticipants()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data.filter((x) => !x.individual)));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get groups.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Listado de usuarios grupales"></CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={
							"¿Estás seguro de que quieres eliminar este grupo?"
						}
						open={openConfirmDialog}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteParticipant(selectedGroup?.id)
								.then((res) => {
									if (res.status === 204) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Group removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Could not remove group.",
									});
								});
						}}
					></ConfirmDialog>
					<PreviewDialog
						title={selectedGroup?.name}
						open={openPreviewDialog}
						setOpen={setOpenPreviewDialog}
						src={`${SERVER_URL}/${selectedGroup?.image}`}
					></PreviewDialog>
					<TableDialog
						title={`Respuestas encuesta de: ${selectedGroup?.name}`}
						open={openSurveyAnswersDialog}
						setOpen={setOpenSurveyAnswersDialog}
						data={getSurveyAnswerData(selectedGroup?.surveyAnswers)}
					></TableDialog>
				</CardBody>
			</Card>
		</>
	);
}
