import { Button, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { getQuizById, updateQuiz } from "../../../../api/quiz";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useSkeleton } from "../../../hooks/useSkeleton";

function getEmptyGame() {
	return {
		fullName: "",
		scenario: "",
		maxScore: "",
		minScore: "",
	};
}

export default function EditQuizzesPage() {
	const [quiz, setQuiz] = useState(getEmptyGame());
	const quizId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!quizId) {
			disableLoadingData();
			history.push("/quizzes");
			return;
		}
		getQuizById(quizId)
			.then((res) => {
				if (res.status === 200) {
					let quiz = {
						...res.data,
						scenario: res.data?.scenario?._id,
					};
					setQuiz(quiz);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get quiz.",
				});
				history.push("/quizzes");
			});
	}, [quizId, disableLoadingData, history]);

	function saveQuiz() {
		updateQuiz(quizId, quiz)
			.then((res) => {
				if (res.status === 200) {
					alertSuccess({
						title: "Saved!",
						customMessage: "Changes successfully saved.",
					});
					history.push("/quizzes");
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not save changes.",
				});
			});
	}

	const handleChange = (element) => (event) => {
		setQuiz({ ...quiz, [element]: event.target.value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit quiz"></CardHeader>
					<CardBody>
						<TextField
							id={`fullName`}
							label="Nombre"
							value={quiz.fullName}
							onChange={handleChange("fullName")}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							variant="outlined"
							required
						/>
						<div className="row">
							<div className="col-md-6">
								<TextField
									id={`score`}
									label="Max Score"
									type="number"
									value={quiz.maxScore}
									onChange={handleChange("maxScore")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
									disabled
								/>
							</div>
							<div className="col-md-6">
								<TextField
									id={`score`}
									label="Min Score"
									type="number"
									value={quiz.minScore}
									onChange={handleChange("minScore")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
									disabled
								/>
							</div>
						</div>
					</CardBody>
				</Card>
				<Button
					onClick={() => history.push("/quizzes")}
					variant="outlined"
					style={{ marginRight: "20px" }}
				>
					Atrás
				</Button>
				<Button
					onClick={() => saveQuiz()}
					variant="outlined"
					color="primary"
					style={{ marginRight: "20px" }}
				>
					Guardar prueba
				</Button>
			</>
		);
}
