/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Group, Person, Receipt } from "@material-ui/icons";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import LocalMoviesIcon from "@material-ui/icons/LocalMovies";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import React from "react";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);
	const location = useLocation();
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
					"menu-item-active"} menu-item-open menu-item-not-hightlighted`
			: "";
	};

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive(
						"/dashboard",
						false
					)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/dashboard">
						<span className="svg-icon menu-icon">
							<SVG
								src={toAbsoluteUrl(
									"/media/svg/icons/Design/Layers.svg"
								)}
							/>
						</span>
						<span className="menu-text">Dashboard</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/rankings",
						false
					)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/rankings">
						<span className="menu-icon">
							<EmojiEventsIcon />
						</span>
						<span className="menu-text">Ranking</span>
					</NavLink>
				</li>
				<li className="menu-section">
					<h4 className="menu-text">PARTIDAS</h4>
					<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/individual-games",
						false
					)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/individual-games">
						<span className="menu-icon">
							<SportsEsportsIcon />
						</span>
						<span className="menu-text">Partidas individuales</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/group-games",
						false
					)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/group-games">
						<span className="menu-icon">
							<GroupWorkIcon />
						</span>
						<span className="menu-text">Partidas grupales</span>
					</NavLink>
				</li>
				<li className="menu-section">
					<h4 className="menu-text">PARTICIPANTES</h4>
					<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/individuals",
						false
					)} ${getMenuItemActive("/edit-individual", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/individuals">
						<span className="menu-icon">
							<Person />
						</span>
						<span className="menu-text">Individuales</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/groups",
						false
					)} ${getMenuItemActive("/edit-group", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/groups">
						<span className="menu-icon">
							<Group />
						</span>
						<span className="menu-text">Grupos</span>
					</NavLink>
				</li>

				<li className="menu-section">
					<h4 className="menu-text">ESCAPE ROOM</h4>
					<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/scenarios",
						false
					)} ${getMenuItemActive("/edit-scenario", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/scenarios">
						<span className="menu-icon">
							<LocalMoviesIcon />
						</span>
						<span className="menu-text">Escenarios</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/quizzes",
						false
					)} ${getMenuItemActive("/edit-quiz", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/quizzes">
						<span className="menu-icon">
							<ContactSupportIcon />
						</span>
						<span className="menu-text">Pruebas</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/surveys",
						false
					)} ${getMenuItemActive("/edit-survey", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/surveys">
						<span className="menu-icon">
							<Receipt />
						</span>
						<span className="menu-text">
							Encuesta de satisfacción
						</span>
					</NavLink>
				</li>
				<li className="menu-section">
					<h4 className="menu-text">GESTiÓN</h4>
					<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/admins",
						false
					)} ${getMenuItemActive("/edit-admin", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/admins">
						<span className="menu-icon">
							<AssignmentIndIcon />
						</span>
						<span className="menu-text">Administradores</span>
					</NavLink>
				</li>

				{/*end::1 Level*/}
			</ul>

			{/* end::Menu Nav */}
		</>
	);
}
