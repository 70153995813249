import { /* baseClient, */ authClient, API } from "../../utils/index";
import { uploadSingleFile } from "../files";

// Get all groups
export const getGroups = () => {
	return authClient().get(`${API}/group`);
};

// Get group by id
export const getGroupById = (id) => {
	return authClient().get(`${API}/group/${id}`);
};

// Get group by game
export const getParticipantsByGame = (id) => {
	return authClient().get(`${API}/participant/game/${id}`);
};

export const getGroupsByGameCase1 = (id, activeCase) => {
	return authClient().get(`${API}/group/game${activeCase}/${id}`);
};

// Delete group
export const deleteGroup = (id) => {
	return authClient().delete(`${API}/group/${id}`);
};

// Create group
export const postGroup = async (group, image) => {
	if (image) {
		const fileURL = await uploadSingleFile(image, "groups");
		group.image = fileURL.data.fileURL;
	}
	return authClient().post(`${API}/group`, group);
};

// Update group
export const updateGroup = async (id, group, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "groups");
		group.image = response.data.fileURL;
	}
	return authClient().put(`${API}/group/${id}`, group);
};
