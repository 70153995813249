import { /* baseClient, */ authClient, API } from "../../utils/index";
import { uploadSingleFile } from "../files";

// Get all participants
export const getParticipants = () => {
	return authClient().get(`${API}/participant`);
};

// Get participant by id
export const getParticipantById = (id) => {
	return authClient().get(`${API}/participant/by-id/${id}`);
};

// Delete participant
export const deleteParticipant = (id) => {
	return authClient().delete(`${API}/participant/${id}`);
};

// Create participant
export const postParticipant = async (participant, image) => {
	if (image) {
		const fileURL = await uploadSingleFile(image, "participants");
		participant.image = fileURL.data.fileURL;
	}
	return authClient().post(`${API}/participant`, participant);
};

// Update participant
export const updateParticipant = async (id, participant, image) => {
	if (image) {
		const fileURL = await uploadSingleFile(image, "participants");
		participant.image = fileURL.data.fileURL;
	}
	return authClient().put(`${API}/participant/${id}`, participant);
};
