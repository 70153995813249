import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../_metronic/layout";
import AdminsPage from "./modules/ContentManager/admins/AdminsPage";
import EditAdminsPage from "./modules/ContentManager/admins/EditAdminsPage";
import GroupGamesPage from "./modules/ContentManager/games/GroupGamesPage";
import IndividualGamesPage from "./modules/ContentManager/games/IndividualGamesPage";
import RankingGamesPage from "./modules/ContentManager/games/RankingGamesPage";
import { ResultsGamePage } from "./modules/ContentManager/games/ResultsGamePage";
import EditParticipantsPage from "./modules/ContentManager/participants/EditParticipantsPage";
import GroupsPage from "./modules/ContentManager/participants/GroupsPage";
import IndividualsPage from "./modules/ContentManager/participants/IndividualsPage";
import EditQuizzesPage from "./modules/ContentManager/quizzes/EditQuizzesPage";
import QuizzesPage from "./modules/ContentManager/quizzes/QuizzesPage";
import ViewQuizPage from "./modules/ContentManager/quizzes/ViewQuizPage";
import ScenesPage from "./modules/ContentManager/scenes/ScenesPage";
import ViewScenePage from "./modules/ContentManager/scenes/ViewScenePage";
import EditSurveysPage from "./modules/ContentManager/surveys/EditSurveysPage";
import SurveysPage from "./modules/ContentManager/surveys/SurveysPage";
import { DashboardPage } from "./pages/DashboardPage";
import RankingPage from "./pages/RankingPage";

export default function BasePage() {
	return (
		<Switch>
			{/* Redirect from root URL to /dashboard. */}
			<Redirect exact from="/" to="/dashboard" />
			<ContentRoute path="/dashboard" component={DashboardPage} />
			<ContentRoute path="/rankings" component={RankingPage} />
			{/* Administrators */}
			<ContentRoute from="/admins" component={AdminsPage} />
			<ContentRoute from="/edit-admin/:id?" component={EditAdminsPage} />
			{/* Games */}
			<ContentRoute
				from="/individual-games/:id/ranking"
				component={RankingGamesPage}
			/>
			<ContentRoute
				from="/group-games/:id/ranking"
				component={RankingGamesPage}
			/>
			<ContentRoute
				from="/individual-games/:id/results"
				component={ResultsGamePage}
			/>
			<ContentRoute
				from="/group-games/:id/results"
				component={ResultsGamePage}
			/>
			<ContentRoute
				from="/individual-games"
				component={IndividualGamesPage}
			/>
			<ContentRoute from="/group-games" component={GroupGamesPage} />

			{/* Participants */}
			<ContentRoute from="/groups" component={GroupsPage} />
			<ContentRoute from="/individuals" component={IndividualsPage} />
			<ContentRoute
				from="/edit-group/:id?"
				component={EditParticipantsPage}
			/>
			<ContentRoute
				from="/edit-individual/:id?"
				component={EditParticipantsPage}
			/>
			{/* Scenes */}
			<ContentRoute from="/scenarios/:id" component={ViewScenePage} />
			<ContentRoute from="/scenarios" component={ScenesPage} />

			{/* Quizzes */}
			<ContentRoute from="/quizzes/:id" component={ViewQuizPage} />
			<ContentRoute from="/quizzes" component={QuizzesPage} />
			<ContentRoute from="/edit-quiz/:id" component={EditQuizzesPage} />

			{/* Survey */}
			<ContentRoute from="/surveys" component={SurveysPage} />
			<ContentRoute
				from="/edit-survey/:id?"
				component={EditSurveysPage}
			/>
			<Redirect to="/error" />
		</Switch>
	);
}
